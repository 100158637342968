import React, { Component } from "react";
import {
  apiURL,
  change_date_format,
  get_changed_time,
  sanitizeHtml,
} from "../utils";
import Footer from "../include/Footer";
import Header from "../include/Header";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  CssBaseline,
  Grid,
  Container,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Snackbar,
  Switch,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  InputAdornment,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import SearchIcon from "@material-ui/icons/Search";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import Delete from "../delete/Delete";
import ClearIcon from "@material-ui/icons/Clear";

import EditIcon from "@material-ui/icons/Edit";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import "../style/Style.css";
import ImageSearchIcon from "@material-ui/icons/ImageSearch";
import FeedbackIcon from "@material-ui/icons/Feedback";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import Meta from "../meta/Meta";
import PushNotification from "../push-notification/PushNotification";
import Moment from "moment";
import XLSX from "xlsx";
import ExportExcelButton from "../utils/ExportExcelButton";
import { ValidationError } from "yup";

const drawerWidth = 280;
// const useStyles = makeStyles((theme) => ({
//    /* textField: {
//         height:'20px'
//     },*/
// }))

const useStyles = (theme) => ({
  buttonEdit: {
    textTransform: "capitalize",
    border: "1px solid #035eab",
    borderRadius: "0px",
    marginRight: "10px",
    padding: "2px 5px",
  },
  buttonAdd: {
    textTransform: "capitalize",
  },
  table: {
    minWidth: 650,
  },
});

class UpcomingWebinar extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      // drawerOpen: false,
      drawerOpen: true,
      showDialog: false,
      selecteDate: new Date(),
      changeDate: "",
      dialogTitle: "",
      file: null,
      event_file: null,
      imagePreview: null,
      eventImagePreview: null,
      imageEdit: false,
      eventImageEdit: false,
      page: 1,
      per: 20,
      total_pages: null,
      loading: false,
      showSnackbar: false,
      snackBarMessage: "",
      severityColor: "",
      mode: "",
      editorVlaue: "",
      selectFromTime: new Date(),
      selectToTime: new Date(),
      speakerData: [],
      webinarData: [],
      speaker: [],
      pointList: [{ point: "" }],
      snackBarTimer: "",
      searchValue: "",
      noData: "",
      getDbDate: "",
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
    this.addDialog = this.addDialog.bind(this);
    this.editDialog = this.editDialog.bind(this);
    this.closeDialog = this.closeDialog.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleFromTimeChange = this.handleFromTimeChange.bind(this);
    this.handleToTimeChange = this.handleToTimeChange.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleEditorChange = this.handleEditorChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.webinarListData = this.webinarListData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.speakerListData();
    this.webinarListData();
    this.scrollListener = window.addEventListener("scroll", (e) => {
      this.handleScroll(e);
    });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  webinarListData = () => {
    this.setState({ loading: true });
    const { per, page, webinarData } = this.state;
    const headers = {
      "Content-type": "application/json",
    };
    const body = {
      event_type: "UE",
      //"search": this.state.searchValue,
    };
    axios
      .post(
        apiURL("webinar/upcoming_webinar?per_page=" + per + "&page=" + page),
        body,
        {
          headers: headers,
        }
      )
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            const updatedData = [
              ...this.state.webinarData,
              ...resultData.result,
            ];
            this.setState({
              webinarData: updatedData,

              scrolling: false,
              loading: false,
              total_pages: resultData.total_pages,
            });
            this.setState({ loading: false, scrolling: true });
          } else {
            this.setState({
              loading: false,
              scrolling: false,
              noData: "",
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  loadMore = () => {
    if (this._isMounted) {
      this.setState(
        (prevState) => ({
          page: prevState.page + 1,
          scrolling: true,
        }),
        this.webinarListData
      );
    }
  };

  handleScroll = () => {
    if (this._isMounted) {
      var lastLi = document.querySelector("Table");
      if (lastLi !== null) {
        var lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
        var pageOffset = window.pageYOffset + window.innerHeight;
        if (pageOffset > lastLiOffset) {
          console.log("scrolling", this.state.scrolling);
          if (this.state.scrolling) {
            this.loadMore();
          }
        }
      }
    }
  };

  speakerListData = () => {
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("training_schedule/speaker"), { headers: headers })
      .then((response) => {
        if (this._isMounted) {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.setState({
              speakerData: resultData.result,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  changeHandler = (e, type) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
    console.log(e.target.value);
  };

  handleImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png"];
  
      if (validImageTypes.includes(file.type)) {
        this.setState({
          imagePreview: URL.createObjectURL(file),
          file: file,
          imageEdit: true,
        });
      } else {
        alert("Only JPEG and PNG images are allowed.");
        this.setState({
          imagePreview: "",
          file: "",
          imageEdit: false,
        });
  
        e.target.value = null;
      }
    }
  };
  
  handleEventImageChange = (e) => {
    const file = e.target.files[0];
  
    if (file) {
      const validImageTypes = ["image/jpeg", "image/png"];
      
      if (validImageTypes.includes(file.type)) {
        this.setState({
          eventImagePreview: URL.createObjectURL(file),
          event_file: file,
          eventImageEdit: true,
        });
      } else {
        alert("Only JPEG and PNG images are allowed.");
        this.setState({
          eventImagePreview: "",
          event_file: "",
          eventImageEdit: false,
        });
  
        e.target.value = null;
      }
    }
  };
  
  removeImage = (e) => {
    this.setState({
      imagePreview: "",
      file: "",
      imageEdit: false,
      image: "",
    });
  };
  removeEventImage = (e) => {
    this.setState({
      eventImagePreview: "",
      event_file: "",
      eventImageEdit: false,
      eventImage: "",
    });
  };
  addDialog = (e) => {
    this.setState({
      mode: "add",
      showDialog: true,
      dialogTitle: "Add Webinar",
    });
  };

  editDialog = (e) => {
    this.setState({
      mode: "edit",
      showDialog: true,
      dialogTitle: "Edit Webinar",
      id: e.fldi_id,
      title: e.fldv_title,
      speaker: e.fldv_speaker_name,
      language: e.fldv_language,
      price: e.fldv_price,
      pointList: JSON.parse(e.fldt_points),
      duration: e.fldv_duration,
      selecteDate: new Date(e.fldv_select_date),
      selectFromTime: new Date(e.fldt_time),
      editorVlaue: e.fldv_description,
      image: e.fldv_image_url,
      eventImage: e.fldv_event_image,
      video_link: e.fldv_video_link,
      webinar_url: e.fldv_webinar_url,
      venue_location: e.fldv_venue_location,
      webinarMode: e.fldv_mode,
    });
  };

  closeDialog = () => {
    this.setState({
      showDialog: false,
      title: "",
      speaker: [],
      price: "",
      language: "",
      points: [],
      duration: "",
      selecteDate: new Date(),
      selectFromTime: new Date(),
      editorVlaue: "",
      image: "",
      file: "",
      event_file: "",
      video_link: "",
      webinar_url: "",
      webinarMode: "",
      venue_location: "",
    });
  };

  handleDateChange = (date) => {
    console.log(date);
    this.setState({
      selecteDate: new Date(date),
      changeDate: change_date_format(date),
    });
  };

  handleFromTimeChange = (date) => {
    this.setState({
      selectFromTime: new Date(date),
      changeDate: change_date_format(date),
    });
    this.setState({
      selectToTime: date,
      changeDate: change_date_format(date),
    });
  };

  handleToTimeChange = (date) => {
    this.setState({
      selectToTime: new Date(date),
      changeDate: change_date_format(date),
    });
  };

  handleEditorChange = (content, editor) => {
    this.setState({
      editorVlaue: content,
    });
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", this.state.title);

    formData.append("speaker_name", this.state.speaker);
    formData.append("language", this.state.language);
    formData.append("select_date", this.state.selecteDate);
    formData.append(
      "change_date",
      change_date_format(this.state.selecteDate) +
        " " +
        get_changed_time(this.state.selecteDate, false)
    );
    formData.append("time", this.state.selectToTime);
    formData.append("price", this.state.price);
    formData.append("duration", this.state.duration);
    formData.append("mode", this.state.webinarMode);
    formData.append("venue_location", this.state.venue_location);
    formData.append("point_list", JSON.stringify(this.state.pointList));
    formData.append("description", this.state.editorVlaue);
    formData.append("file", this.state.file);
    formData.append("event_image", this.state.event_file);
    formData.append("video_link", this.state.video_link);
    formData.append("webinar_url", this.state.webinar_url);

    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("webinar/add"), formData, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          this.closeDialog();
          //   this.componentDidMount();
          this.setState({
            imageEdit: false,
            imagePreview: null,
            file: null,
            eventImageEdit: false,
            eventImagePreview: null,
            event_file: null,
          });
          this.setState({
            webinarData: [resultData.result, ...this.state.webinarData],
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: resultData.status === true ? "success" : "error",
            snackBarTimer: 3000,
          });
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  onEditFormSubmit = (e) => {
    e.preventDefault();
    const editFormData = new FormData();
    editFormData.append("id", this.state.id);
    editFormData.append("title", this.state.title);
    editFormData.append("speaker_name", this.state.speaker);
    editFormData.append("language", this.state.language);
    editFormData.append("select_date", this.state.selecteDate);
    editFormData.append(
      "change_date",
      change_date_format(this.state.selecteDate) +
        " " +
        get_changed_time(this.state.selecteDate, false)
    );
    editFormData.append("time", this.state.selectFromTime);
    editFormData.append("price", this.state.price);
    editFormData.append("duration", this.state.duration);
    editFormData.append("mode", this.state.webinarMode);
    editFormData.append("venue_location", this.state.venue_location);
    editFormData.append("point_list", JSON.stringify(this.state.pointList));
    editFormData.append("description", this.state.editorVlaue);
    if (this.state.file !== null) {
      editFormData.append("file", this.state.file);
    }
    editFormData.append("exist_file", this.state.image);
    editFormData.append("image_change", this.state.imageEdit);
    if (this.state.event_file !== null) {
      editFormData.append("event_image", this.state.event_file);
    }
    editFormData.append("exist_event_file", this.state.eventImage);
    editFormData.append("event_image_change", this.state.eventImageEdit);
    editFormData.append("video_link", this.state.video_link);
    editFormData.append("webinar_url", this.state.webinar_url);
    const headers = {
      "content-type": "multipart/form-data",
      Accept: "application/json",
    };
    axios
      .post(apiURL("webinar/edit"), editFormData, { headers: headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedWebinarIndex = this.state.webinarData.findIndex(
            (webinar) => webinar.fldi_id === this.state.id
          );
          if (updatedWebinarIndex !== -1) {
            const updatedWebinarData = [...this.state.webinarData];
            updatedWebinarData[updatedWebinarIndex] = resultData.result;

            this.setState({
              webinarData: updatedWebinarData,
              imageEdit: false,
              imagePreview: null,
              file: null,
              eventImageEdit: false,
              eventImagePreview: null,
              event_file: null,
              showSnackbar: true,
              snackBarMessage: resultData.message,
              severityColor: resultData.status === true ? "success" : "error",
              snackBarTimer: 3000,
            });
          }
          this.closeDialog();
        } else {
          this.setState({
            showSnackbar: true,
            snackBarMessage: resultData.message,
            severityColor: "error",
            snackBarTimer: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  changeStatus = (id, status) => {
    console.log(status);
    const headers = {
      "Content-type": "application/json",
    };
    axios
      .get(apiURL("webinar/change_status/" + id + "/" + status), {
        headers: headers,
      })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          const updatedTrainingSchedule = this.state.webinarData.map((ts) => {
            if (ts.fldi_id === id) {
              const _ts = { ...ts };
              _ts.flg_status = status === "0" ? "1" : "0";
              return _ts;
            }
            return ts;
          });
          this.setState({ webinarData: updatedTrainingSchedule });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  changeCancelStatus = (id, title) => {
    if (window.confirm("Are you sure? You want to cancel this event")) {
      const headers = {
        "Content-type": "application/json",
      };
      const body = {
        webinar_id: id,
        title: title,
      };
      axios
        .post(apiURL("webinar/cancel_webinar"), body, {
          headers: headers,
        })
        .then((response) => {
          const resultData = response.data;
          if (resultData.status === true || resultData.status === 1) {
            this.changeStatus(id, 1);
            const index = this.state.webinarData.findIndex(
              (event) => event.fldi_id === id
            );
            if (index !== -1) {
              const updatedEventsList = [...this.state.webinarData];
              updatedEventsList[index].flg_event_status = "cancelled";
              this.setState({
                webinarData: updatedEventsList,
              });
            }
          } else {
            console.log("error");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };
  handleData = (webinar_id) => {
    this.props.history.push("/registered_user", { webinar_id });
  };
  pointListChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.pointList];
    list[index][name] = value;
    this.setState({ pointList: list });
  };

  pointAddClick = () => {
    this.setState({ pointList: [...this.state.pointList, { point: "" }] });
  };

  pointRemoveClick = (index) => {
    const list = [...this.state.pointList];
    list.splice(index, 1);
    //setInputList(list);
    this.setState({ pointList: list });
  };

  snackBarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ showSnackbar: false });
  };

  searchHandler = (e) => {
    this.setState({ page: 1, searchValue: e.target.value }, () => {
      this.webinarListData();
    });
    //this.webinarListData();
  };

  successDelete = (e, id, index) => {
    if (e === true) {
      const deleteData = this.state.webinarData;
      deleteData.splice(index, 1);
      this.setState({
        webinarData: deleteData,
        showSnackbar: true,
        snackBarMessage: "data deleted suceesfully",
        severityColor: "success",
        snackBarTimer: 3000,
      });
    }
  };

  exportFile() {
    let headers = [
      [
        "Title",
        "Schedule Date",
        "Time",
        "Price",
        "Duration",
        "Speaker",
        "Language",
        "Points",
        "Image",
        "Video Link",
        "Webinar Link",
        "Status",
        "Delete",
      ],
    ];
    this.state.webinarData.forEach((value) => {
      const arrSpeakerPush = [];
      value.speaker.map((arrSpeaker, index) => {
        arrSpeakerPush.push(arrSpeaker.speaker_name);
      });

      const point = JSON.parse(value.fldt_points);
      const arrPointsPush = [];
      point.map((arrPoint, index) => {
        //(index+1)+'.'+arrPoint.point
        arrPointsPush.push(index + 1 + "." + arrPoint.point);
      });
      let valueArray = [
        value.fldv_title,
        value.fldv_changed_date,
        get_changed_time(value.fldt_time, true),
        value.fldv_price,
        value.fldv_duration,
        value.fldv_language,
        value.fldv_speaker_name,
        arrPointsPush.join(", "),
        value.fldv_image_url,
        value.fldv_video_link,
        value.fldv_webinar_url,
        value.flg_status === "1" ? "Active" : "Deactive",
        value.flg_is_deleted === "1" ? "Not Delete" : "Deleted",
      ];
      headers.push(valueArray);
    });

    const wb = XLSX.utils.book_new();
    const wsAll = XLSX.utils.aoa_to_sheet(headers);

    XLSX.utils.book_append_sheet(wb, wsAll, "Webinar");
    XLSX.writeFile(wb, "export-webinar.xlsx");
  }

  render() {
    const { classes } = this.props;
    const loadingCSS = {
      height: "30px",
      margin: "10px",
    };
    const loadingTextCSS = { display: this.state.loading ? "block" : "none" };

    return (
      <React.Fragment>
        <Helmet>
          <title>Webinar</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={
                    <div>
                      <TextField
                        className="textField"
                        type="text"
                        placeholder="...Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          style: {
                            color: "#414141",
                            backgroundColor: "rgba(255, 255, 255, 0.29)",
                          },
                        }}
                        size="small"
                        id="search"
                        variant="outlined"
                        style={{ fontColor: "#fff", marginRight: "1rem" }}
                        name="search"
                        onChange={(e) => this.searchHandler(e)}
                      />
                      <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={(e) => this.addDialog(e)}
                      >
                        {" "}
                        Add Webinar{" "}
                      </Button>{" "}
                      <ExportExcelButton
                        clickProp={(e) => this.exportFile(e)}
                      />
                    </div>
                  }
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2>Upcoming Events </h2>
                </Alert>
              </Box>
              <Grid container spacing={3} align="center">
                <Grid item xs={12}>
                  <Box
                    bgcolor="background.paper"
                    boxShadow={3}
                    px={2}
                    py={2}
                    mt={1}
                  >
                    <TableContainer
                      mt={1}
                      component={Paper}
                      className="customTable"
                    >
                      <Table
                        size="small"
                        stickyHeader
                        aria-label="sticky table"
                      >
                        <TableHead>
                          <TableCell>Id</TableCell>
                          <TableCell> Title</TableCell>
                          {/*<TableCell>Description</TableCell>
                                            <TableCell>Speaker</TableCell>*/}
                          <TableCell>Schedule Date</TableCell>
                          <TableCell>Time</TableCell>
                          <TableCell>Price</TableCell>
                          <TableCell>Duration</TableCell>
                          <TableCell>No of Registrations</TableCell>
                          <TableCell>No of Shares</TableCell>
                          {/*<TableCell>Points</TableCell> 
                                            <TableCell>Image</TableCell>*/}
                          <TableCell>Status</TableCell>
                          <TableCell>Cancel</TableCell>
                          <TableCell>Action</TableCell>
                        </TableHead>
                        <TableBody>
                          {this.state.webinarData.map((arrList, index) => {
                            return (
                              <TableRow key={arrList.fldi_id}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>{arrList.fldv_title}</TableCell>
                                {/*<TableCell>{sanitizeHtml(arrList.fldv_description)}</TableCell>
                                                    <TableCell><SpeakerListArray speakers={arrList.speaker}/></TableCell>*/}
                                <TableCell>
                                  {arrList.fldv_changed_date}
                                </TableCell>
                                <TableCell>
                                  {get_changed_time(arrList.fldt_time, true)}
                                </TableCell>
                                <TableCell>{arrList.fldv_price}</TableCell>
                                <TableCell>
                                  {arrList.fldv_duration} Hour
                                </TableCell>
                                <TableCell
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.handleData(arrList.fldi_id)
                                  }
                                >
                                  {arrList.no_of_register_user}
                                </TableCell>
                                <TableCell>{arrList.no_of_share}</TableCell>
                                {/*<TableCell><PointListItem pointsListData={arrList.fldt_points} /></TableCell>
                                                    <TableCell><img style={{width:"40px"}} src={arrList.fldv_image_url} alt={arrList.fldv_name} /></TableCell>*/}
                                <TableCell>
                                  {" "}
                                  <Switch
                                    size="small"
                                    checked={arrList.flg_status === "1"}
                                    color="primary"
                                    onChange={() =>
                                      this.changeStatus(
                                        arrList.fldi_id,
                                        arrList.flg_status
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  {" "}
                                  <Switch
                                    size="small"
                                    checked={ arrList.flg_status === "1"?false:
                                      arrList.flg_event_status === "cancelled"
                                    }
                                    color="primary"
                                    disabled={
                                      arrList.flg_event_status === "cancelled"
                                    }
                                    onChange={() =>
                                      this.changeCancelStatus(
                                        arrList.fldi_id,
                                        arrList.fldv_title
                                      )
                                    }
                                  />
                                </TableCell>
                                <TableCell>
                                  <Button
                                    className={classes.buttonEdit}
                                    variant="outlined"
                                    color="default"
                                    startIcon={
                                      <EditIcon
                                        style={{
                                          color: "#035eab",
                                          fontSize: "1rem",
                                        }}
                                      />
                                    }
                                    onClick={() => this.editDialog(arrList)}
                                  >
                                    Edit
                                  </Button>
                                  <Link
                                    to={
                                      "webinar-feedback/" +
                                      arrList.fldi_id +
                                      "/" +
                                      arrList.fldv_title
                                        .replace(/ /g, "-")
                                        .toLowerCase()
                                    }
                                  >
                                    <Button
                                      className={classes.buttonEdit}
                                      variant="outlined"
                                      color="default"
                                      startIcon={
                                        <FeedbackIcon
                                          style={{
                                            color: "#035eab",
                                            marginLeft: "5PX",
                                            fontSize: "0.875rem",
                                          }}
                                        />
                                      }
                                    >
                                      Feedback
                                    </Button>
                                  </Link>
                                  <Link
                                    to={"join-webinar-user/" + arrList.fldi_id}
                                  >
                                    <Button
                                      className={classes.buttonEdit}
                                      variant="outlined"
                                      color="default"
                                      startIcon={
                                        <GroupAddIcon
                                          style={{
                                            color: "#035eab",
                                            marginLeft: "5px",
                                          }}
                                        />
                                      }
                                      onClick={() => this.editDialog(arrList)}
                                    >
                                      Join Users
                                    </Button>
                                  </Link>
                                  <Delete
                                    deleteID={arrList.fldi_id}
                                    table={"webinar"}
                                    onSuccessDelete={(e) =>
                                      this.successDelete(
                                        e,
                                        arrList.fldi_id,
                                        index
                                      )
                                    }
                                  />
                                  <Meta
                                    primaryId={arrList.fldi_id}
                                    useFor={"inner_page"}
                                    table={"webinar"}
                                    onSuccessDelete={(e) =>
                                      this.successDelete(
                                        e,
                                        arrList.fldi_id,
                                        index
                                      )
                                    }
                                  />

                                  <PushNotification
                                    id={arrList.fldi_id}
                                    body={arrList.fldv_description}
                                    title={arrList.fldv_title}
                                    url={"webinar"}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>

                  <div
                    ref={(loadingRef) => (this.loadingRef = loadingRef)}
                    style={loadingCSS}
                  >
                    <span style={loadingTextCSS}>Loading...</span>
                    <span>{this.state.scrolling ? this.state.noData : ""}</span>
                  </div>
                </Grid>
              </Grid>
              <Footer />
            </Container>
          </main>
        </div>

        <Dialog
          open={this.state.showDialog}
          fullWidth={true}
          maxWidth={"md"}
          disableEnforceFocus
        >
          <form
            id="courses"
            onSubmit={
              this.state.mode === "edit"
                ? (e) => this.onEditFormSubmit(e)
                : (e) => this.onFormSubmit(e)
            }
          >
            <DialogTitle id="form-dialog-title">
              <h3 style={{ textAlign: "center" }}>{this.state.dialogTitle}</h3>
            </DialogTitle>
            <DialogContent style={{ overflowY: "hidden" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Title"
                    variant="outlined"
                    name="title"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.title}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Speaker"
                    variant="outlined"
                    name="speaker"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.speaker}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Webinar Url"
                    variant="outlined"
                    name="webinar_url"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.webinar_url}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <InputLabel>
                      Language<span>*</span>
                    </InputLabel>
                    <Select
                      name="language"
                      label="Language"
                      required
                      value={this.state.language}
                      onChange={(e) => this.changeHandler(e)}
                    >
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Marathi">Marathi</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    type="text"
                    size="small"
                    label="Venue/Location"
                    variant="outlined"
                    name="venue_location"
                    onChange={(e) => this.changeHandler(e)}
                    value={this.state.venue_location}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl
                    variant="outlined"
                    size="small"
                    style={{ width: "100%" }}
                  >
                    <InputLabel>Mode</InputLabel>
                    <Select
                      name="webinarMode"
                      label="Mode"
                      value={this.state.webinarMode}
                      onChange={(e) => this.changeHandler(e)}
                    >
                      <MenuItem value="Online">Online</MenuItem>
                      <MenuItem value="Physical">Physical</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        label="Price"
                        variant="outlined"
                        name="price"
                        onChange={(e) => this.changeHandler(e)}
                        value={this.state.price}
                        required
                        placeholder="Price"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        fullWidth
                        type="text"
                        size="small"
                        label="Duration"
                        variant="outlined"
                        name="duration"
                        onChange={(e) => this.changeHandler(e)}
                        value={this.state.duration}
                        required
                        placeholder="Duration in Hour"
                      />
                    </Grid>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <Grid item xs={6} sm={3}>
                        <FormControl size="small" style={{ width: "100%" }}>
                          <KeyboardDatePicker
                            size="small"
                            InputProps={{ size: "small" }}
                            inputVariant="outlined"
                            label="Choose Date"
                            value={this.state.selecteDate}
                            onChange={(date) => this.handleDateChange(date)}
                            name="date"
                            format="dd/MM/yyyy"
                            showTodayButton
                            autoOk
                            variant="inline"
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl size="small" style={{ width: "100%" }}>
                          <KeyboardTimePicker
                            size="small"
                            ampm={true}
                            variant="inline"
                            inputVariant="outlined"
                            label="Start Time"
                            value={this.state.selectFromTime}
                            onChange={(date) => this.handleFromTimeChange(date)}
                          />
                        </FormControl>
                      </Grid>
                    </MuiPickersUtilsProvider>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={12}>
                  {this.state.pointList.map((x, i) => (
                    <Grid container spacing={3} key={i}>
                      <Grid item xs={10} sm={10}>
                        <TextField
                          fullWidth
                          type="text"
                          size="small"
                          label="Point"
                          variant="outlined"
                          name="point"
                          onChange={(e) => this.pointListChangeHandler(e, i)}
                          value={x.point}
                          required
                        />
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        {this.state.pointList.length !== 1 && (
                          <Button
                            type="button"
                            variant="contained"
                            color="secondary"
                            onClick={() => this.pointRemoveClick(i)}
                          >
                            Remove
                          </Button>
                        )}
                        {this.state.pointList.length - 1 === i && (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => this.pointAddClick()}
                          >
                            Add Points
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12} sm={12}>
                  <Editor
                    initialValue={this.state.editorVlaue}
                    init={{
                      height: 188,
                      selector: "textarea",
                      convert_urls: false,
                      menubar: true,
                      plugins: [
                        "advlist autolink tinydrive lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      api_key:
                        "v2xbwrmnf5ixj35bcuymns7odhg03cvh4xuyud4wc0zjc0lh",
                      tinydrive_token_provider:
                        "https://navdishaadmin.navneet.com/jwt/text_editor_key/api/jwt.php",
                      toolbar:
                        "undo redo | formatselect | bold italic underline backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help",
                    }}
                    onEditorChange={(e) => this.handleEditorChange(e)}
                  />
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={8}
                  spacing={2}
                  alignItems="center"
                >
                  <Grid item>
                    {this.state.imagePreview || this.state.image ? (
                      <ClearIcon onClick={(e) => this.removeImage(e)} />
                    ) : (
                      ""
                    )}
                    {this.state.imageEdit === true ? (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.imagePreview}
                      />
                    ) : (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.image}
                      />
                    )}
                    <Button
                      variant="contained"
                      startIcon={<ImageSearchIcon />}
                      color="primary"
                      component="label"
                    >
                      Select Main Image
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleImageChange}
                      />
                    </Button>
                  </Grid>
                  <Grid item>
                    {this.state.eventImagePreview || this.state.eventImage ? (
                      <ClearIcon onClick={(e) => this.removeEventImage(e)} />
                    ) : (
                      ""
                    )}
                    {this.state.eventImageEdit === true ? (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.eventImagePreview}
                      />
                    ) : (
                      <img
                        style={{
                          width: "40px",
                          display: "flex",
                          marginBottom: "1rem",
                          marginLeft: "12%",
                        }}
                        src={this.state.eventImage}
                      />
                    )}
                    <Button
                      variant="contained"
                      startIcon={<ImageSearchIcon />}
                      color="primary"
                      component="label"
                      style={{ marginLeft: "2px" }}
                    >
                      Select Event Creative
                      <input
                        type="file"
                        style={{ display: "none" }}
                        onChange={this.handleEventImageChange}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>

            <DialogActions className="buttonCancel">
              <Button
                variant="contained"
                color="secondary"
                onClick={(e) => this.closeDialog(e)}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {" "}
                Submit{" "}
              </Button>
            </DialogActions>
          </form>
        </Dialog>

        <Snackbar
          open={this.state.showSnackbar === true}
          autoHideDuration={this.state.snackBarTimer}
          onClose={this.snackBarClose}
        >
          <Alert
            onClose={this.snackBarClose}
            severity={this.state.severityColor}
            elevation={6}
            variant="filled"
          >
            {this.state.snackBarMessage}
          </Alert>
        </Snackbar>
      </React.Fragment>
    );
  }
}

function PointListItem(props) {
  const point = JSON.parse(props.pointsListData);
  return (
    <ol>
      {point.map((arrPoint, index) => {
        return <li>{arrPoint.point}</li>;
      })}
    </ol>
  );
}

function SpeakerListArray(props) {
  const arrSpeakerPush = [];
  return (
    <div>
      {props.speakers.map((arrSpeaker, index) => {
        arrSpeakerPush.push(arrSpeaker.speaker_name);
      })}
      <span>{arrSpeakerPush.join(", ")}</span>
    </div>
  );
}

export default withStyles(useStyles)(UpcomingWebinar);
