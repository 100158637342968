import React, { Component } from "react";
import { Grid, Paper, CssBaseline, Box, Container } from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import axios from "axios";
import { apiURL } from "../utils";
import "../training_schedule/TrainingMenu.css";
import Header from "../include/Header";
import Helmet from "react-helmet";
import registereduser from "../images/registereduser.png";
import Alert from "@material-ui/lab/Alert";
import Footer from "../include/Footer";
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#ad180d"];

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.1)",
  },
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: "2rem",
    marginLeft: "3rem",
    marginRight: "3rem",
    paddingTop: theme.spacing(5),
  },
  item: {
    maxWidth: 345,
  },
});

const drawerWidth = 280;

class TrainingMenu extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: true,
      totalUser: "",
      migratedUser: "",
      newUser: "",
      pendingUser: "",
    };
    this.handleDrawerOpen = this.handleDrawerOpen.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDrawerOpen(drawerState) {
    this.setState({
      drawerOpen: drawerState,
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <React.Fragment>
        <Helmet>
          <title>Training Schedule</title>
        </Helmet>
        <Header forwardDrawerOpen={this.handleDrawerOpen.bind(this)} />
        <CssBaseline />
        <div
          style={{
            paddingTop: "5rem",
            paddingLeft: this.state.drawerOpen ? drawerWidth + 15 : 72 + 15,
            transition: "padding 0.5s ease",
          }}
        >
          <main>
            <Container maxWidth="lg">
              <Box bgcolor="background.paper" mt={2} boxShadow={3}>
                <Alert
                  action={
                    <div>
                      {/* <TextField
                        className="textField"
                        type="text"
                        placeholder="...Search"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                          style: {
                            color: "#414141",
                            backgroundColor: "rgba(255, 255, 255, 0.29)",
                          },
                        }}
                        size="small"
                        id="search"
                        variant="outlined"
                        style={{ fontColor: "#fff", marginRight: "1rem" }}
                        name="search"
                        onChange={(e) => this.searchHandler(e)}
                      /> */}
                      {/* <Button
                        className={classes.buttonAdd}
                        variant="contained"
                        color="primary"
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={(e) => this.addDialog(e)}
                      >
                        {" "}
                        Add Training Schedule{" "}
                      </Button>
                      <ExportExcelButton
                        clickProp={(e) => this.exportFile(e)}
                      /> */}
                    </div>
                  }
                  variant="outlined"
                  severity="default"
                  boxShadow={3}
                >
                  <h2>Training</h2>
                </Alert>
              </Box>
              <div className="TrainingMenu">
                <Grid container spacing={2} columns={16}>
                  <Grid item xs={8} className={classes.item}>
                    <Paper className={classes.paper}>
                      {/* <Link to="/user-count"> */}
                      <img
                        src={registereduser}
                        width={99}
                        height={99}
                        alt="User Information"
                      />
                      <h3>Overview</h3>
                      {/* </Link> */}
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.item}>
                    <Paper className={classes.paper}>
                      <Link to="/upcoming-webinar">
                        <img
                          src={process.env.PUBLIC_URL + "/images/webinar.png"}
                        />

                        <h3>Upcoming Events</h3>
                      </Link>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.item}>
                    <Paper className={classes.paper}>
                      <Link to="/training-schedule">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/training-schedule.png"
                          }
                        />
                        <h3>Past Trainings</h3>
                      </Link>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.item}>
                    <Paper className={classes.paper}>
                      <Link to="/webinar">
                        <img
                          src={process.env.PUBLIC_URL + "/images/webinar.png"}
                          alt="pastwebinar"
                        />
                        <h3>Past Webinars</h3>
                      </Link>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} className={classes.item}>
                    <Paper className={classes.paper}>
                      <Link to="/video">
                        <img
                          src={process.env.PUBLIC_URL + "/images/resources.png"}
                        />
                        <h3>Educational Videos</h3>
                      </Link>
                    </Paper>
                  </Grid>
                </Grid>
              </div>
              {/* <Footer /> */}
            </Container>
          </main>
        </div>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(TrainingMenu);
